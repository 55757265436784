import { useEffect, useState } from "react"
import { useStoreCtx } from "../../../store"
import { ssoPhoneToMasked } from "../../../util/helpers"
import { Subscription } from "../../../util/promo"
import { KionButton } from "../../button/button"
import { KionSelect, KionSelectOption } from "../../select/select"
import { KionCard } from "../card/card"
import { PaymentStep } from "../subscription"
import s from './style.module.scss'
import { observer } from "mobx-react-lite"

export const PaymentInit = observer((props: {
    subscription: Subscription
    onNext: (step: PaymentStep) => void
}) => {
    const httpService = useStoreCtx().appStore

    const [selectedValue, setSelectedValue] = useState<PaymentStep | null>(null)
	const [selectedSub, setSelectedSub] = useState<boolean>(!!props.subscription)
    const [options, setOptions] = useState<KionSelectOption<PaymentStep>[]>([
        {
            label: ssoPhoneToMasked(httpService.phone),
            tooltip: 'Для абонентов МТС',
            icon: '/images/payment-icons/phone.svg',
            value: PaymentStep.Phone
        },
        {
            label: 'Банковской картой',
            tooltip: 'Для абонентов любых операторов',
            icon: '/images/payment-icons/card.svg',
            value: PaymentStep.Card
        }
    ])

    useEffect(() => {
        httpService.isMtsAccount().then((res: any) => {
            const { saleBlock } = res.data
            console.log('saleBlock ----' + saleBlock)
            if (saleBlock) {
                setOptions([
                    {
                        label: 'Банковской картой',
                        tooltip: 'Для абонентов любых операторов',
                        icon: '/images/payment-icons/card.svg',
                        value: PaymentStep.Card
                    }
                ])
            }
        })
    }, [])

    function changeValue(val: number) {
        setSelectedValue(val)
    }

    function onNextStep() {
        if (selectedValue) props.onNext(selectedValue)
    }

    console.log('selectedValue', selectedValue);

    return (
        <div className={s.content}>
            <div className={s['content-wrapper']}>
                <KionCard showButton={false} subscription={props.subscription} />

                <div className={s.actions}>
					<div className={s.type}>
                        <div className={s.label}>
                            Тип подписки
                        </div>
                        <div className={s.subscription__type}>
                            {`${props.subscription.discountPrice / 100} ₽ за ${props.subscription.promotion.timeToLiveValue} мес.`}
                        </div>
                    </div>

                    <KionSelect 
                        label="Способ оплаты"
                        options={options}
                        value={selectedValue}
                        placeholder="Выберите способ оплаты"
                        onSelect={changeValue} 
                    />

                    <KionButton 
                        disabled={selectedValue === null || !selectedSub}
                        onClick={onNextStep}
                    >
                        Оформить подписку
                    </KionButton>
                </div>
            </div>
        </div>
    )
})
